<template>
  <v-snackbar :model-value="modelValue"
              :timeout="timeout"
              :color="color"
              @update:modelValue="emits('update:modelValue', $event)"
  >
    <base-text class="pa-1" color="white">
      {{ text }}
    </base-text>
  </v-snackbar>
</template>

<script setup lang="ts">

import BaseText from '@/components/Texts/BaseText.vue';

const props = defineProps({
  modelValue: Boolean,
  text: { type: String, default: '' },
  color: { type: String, default: 'success' },
  timeout: { type: Number, default: 3000 },
});

const emits = defineEmits(['update:modelValue']);
</script>

<style scoped>

</style>
